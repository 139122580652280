import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { ClientsResolver } from './route-helpers/clients.resolver';
import { AppComponent } from './app.component';

import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
//import { LoginComponent } from './login/login.component';
import { LoginBlankComponent } from "./login/login-blank.component";
import { LogoutComponent } from './logout/logout.component';
import { LoginExternalComponent } from './login/login-external.component';
import { LoginPDFComponent } from './login/login-pdf.component';
import { LoginUserComponent } from './login/login-user.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordValidateComponent } from './forgot-password/forgot-password-validate.component';

//Okta
import { LoginOtkaComponentIDPNoCookies } from './login-okta/login-okta-idp-no-cookies.component';
import { LoginOtkaCallbackComponent } from './login-okta/login-okta-callback.component';

const routes: Routes = [

  // external login application path
  {
    path: 'application/login/:token',
    component: LoginExternalComponent
  },

  {
    path: 'pdf/login/:clientId/:dashboardId/:token',
    component: LoginPDFComponent
  },

  {
    path: 'user/login/:token',
    component: LoginUserComponent
  },

  // user login path
  {
    path: 'login',
    //component: LoginComponent
    redirectTo: 'login-new'
  },
  {
    path: 'login-blank',
    component: LoginBlankComponent
  },

  {
    path: 'logout',
    component: LogoutComponent
  },

  // reset password path
  {
    path: 'reset-password',
    component: ForgotPasswordComponent,
    //canActivate: [OktaAuthGuard]
  },

  // reset password validation path
  {
    path: 'reset-password/:token',
    component: ForgotPasswordValidateComponent
  },

  //Otka
  {
    path: 'login-new',
    component: LoginOtkaComponentIDPNoCookies,
  },
  {
    path: 'login-new/callback',
    component: LoginOtkaCallbackComponent,
  },

  {
    path: '',
    canActivate: [AuthGuardService],  // protect all routes with AuthGuardService
    component: AppComponent,
    resolve: {
      clients: ClientsResolver  // ensure clients data is fetched on route for app component
    },
    children: [
      {
        path: 'dashboards',
        loadChildren: () => import('app/dashboards/dashboards.module').then(m => m.DashboardsModule)
      },
      {
        path: 'my-downloads',
        loadChildren: () => import('app/my-downloads/my-downloads.module').then(m => m.MyDownloadsModule)
      },
      {
        path: 'custom-dash',
        loadChildren: () => import('app/custom-dashboards/custom-dashboards.module').then(m => m.CustomDashBoardsModule)
      },
      {
        path: 'ma',
        loadChildren: () => import('app/media-automation/media-automation.module').then(m => m.MediaAutomationModule)
      },
      {
        path: 'data-warehouse',
        loadChildren: () => import('app/data-warehouse/data-warehouse.module').then(m => m.DataWarehouseModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('app/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule)
      }

    ]
  },

  // root path
  // if user navigates to an undefined route, redirect to PageNotFoundComponent
  {
    path: '**',
    component: PageNotFoundComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
