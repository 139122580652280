/**
 * development environment configuration values
 * environment.development.ts
 *
 * Default values are inherited from the environment.base.ts file,
 * which may be overridden here.
 */

export const environment = {
  production: false,
  api: {
    url: "https://api.insighter.dev.ansira.io"
  },
  sisense: {
    enabled: false,
    appUrl: "https://sisense03.ansira.io",
    logoutUrl: "https://sisense03.ansira.io/api/auth/logout"
  },
  dateFilter: {
    dateFormat: "MM/DD/YY",
    pruneFilters: ["explicit", "members", "multiSelection"]
  },
  metaDataFilter: {
    pruneFilters: ["all", "filter"]
  },
  dynamicFilter: {
    whitelist: [
      "orderlineID",
      "Market",
      "Device",
      "Calendar Month",
      "Calendar Year",
      "Source"
    ],
    pruneFilters: ["merged", "filter", "explicit", "all"]
  },
  navigation: {
    paginationLimit: 150
  },
  spinner: {
    blacklist: ["/login", "/reset-password"]
  },
  scripts: [],
  mediaAutomationUrl: 'https://media-automation-front-end.s3-website-us-west-2.amazonaws.com',
  mediaAutomationApi: 'https://dsta3mym3g.execute-api.us-west-2.amazonaws.com/dev',
  bulkProcessingUrl: 'https://mediaautomationbulkprocessing-env.iwkuerq8mb.us-west-2.elasticbeanstalk.com',
  pdfUrl: 'https://2lr7nhhwk5.execute-api.us-west-2.amazonaws.com/dev',
  pdfLoginUrl: 'https://insighter.dev.ansira.io',
  okta: {
    clientId: '0oaem129mCvQiVRUp1d6',
    baseUrl: 'https://dev.login.ansira.com',
    issuer: 'https://dev.login.ansira.com/oauth2/default',
    redirectUri: 'https://insighter.dev.ansira.io/login-new/callback',
    requestContext: 'https://dev.login.ansira.com/home/oidc_client/0oaem129mCvQiVRUp1d6/aln177a159h7Zf52X0g8'
  }
};
